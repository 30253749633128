export const keuzenData = [{
    id: 'ALine',
    default: 'Aline is de basis vouwfiets met 3 versnellingen en een standaard zadel en medium stuur. De fiets is verkrijgbaar in papyrus grey.',
    versnelling: 'Aline is de basis vouwfiets met 3 versnellingen en een standaard zadel en medium stuur. De fiets is verkrijgbaar in papyrus grey.',
    zadel: 'Aline is de basis vouwfiets met 3 versnellingen en een standaard zadel en medium stuur. De fiets is verkrijgbaar in papyrus grey.',
    zadelHoogte: 'Aline is de basis vouwfiets met 3 versnellingen en een standaard zadel en medium stuur. De fiets is verkrijgbaar in papyrus grey.',
    rack: 'Aline is de basis vouwfiets met 3 versnellingen en een standaard zadel en medium stuur. De fiets is verkrijgbaar in papyrus grey.',
    stuur: 'Aline is de basis vouwfiets met 3 versnellingen en een standaard zadel en medium stuur. De fiets is verkrijgbaar in papyrus grey.',
    verlichting: 'Aline is de basis vouwfiets met 3 versnellingen en een standaard zadel en medium stuur. De fiets is verkrijgbaar in papyrus grey.',
    kleur: 'Aline is de basis vouwfiets met 3 versnellingen en een standaard zadel en medium stuur. De fiets is verkrijgbaar in papyrus grey.',
    },{
    id: 'CLine',
    default: 'Cline is de vouwfiets met 2 of 6 versnellingen en een verschillende type zadels. De fiets is verkrijgbaar in 6 kleuren.',
    versnelling: 'De Cline is verkrijgbaar met 2 of 6 versnellingen. 2 versnellingen is ideal voor in de stad en 6 versnellingen voor langere afstanden.',
    zadel: 'De Cline is verkrijgbaar met 4 verschillende type zadels. De B17 en C17 zijn brooks zadels van hoge kwaliteit en groot zit confort.',
    zadelHoogte: 'De Cline is verkrijgbaar met 3 verschillende zadel hoogtes. extended is 5 cm hoger en telescopic is 10 cm hoger.',
    rack: 'De Cline komt met spatborden en bagagedrager. De bagagedrager is handig voor het vervoeren van bagage en tevens de standaard.',
    stuur: 'De Cline is verkrijgbaar met 3 type sturen. in nederland rijden veel H sturen. maar voor een sportive zit is een S stuur aan te raden.',
    verlichting: 'De Cline is verkrijgbaar met 3 type verlichting. reflector is standaard en dynamo is de meest duurzame optie. maar ook batterij is mogelijk.',
    kleur: 'De CLine is verkrijgbaar in 6 kleuren. Black Matt, Bumblebee Yellow, Dune Sand, Gloss Black Laquer, Ocean Blue, Racing Green.',
    },{
    id: 'CLineElectric',
    default: 'De CLine Electric is de elektrische vouwfiets met 4 of 12 versnellingen. De fiets is verkrijgbaar in 6 kleuren.',
    versnelling: 'De CLine Electric is verkrijgbaar met 4 of 12 versnellingen. 4 versnellingen is ideal voor in de stad en 12 versnellingen voor langere afstanden.',
    zadel: 'De CLine Electric is verkrijgbaar met 2 verschillende type zadels. De standaard wide is een breder zadel voor meer comfort.',
    zadelHoogte: 'De CLine Electric is verkrijgbaar met 3 verschillende zadel hoogtes. extended is 5 cm hoger en telescopic is 10 cm hoger.',
    rack: 'De CLine Electric komt met spatborden en bagagedrager. De bagagedrager is handig voor het vervoeren van bagage en tevens de standaard.',
    stuur: 'De CLine Electric is verkrijgbaar met 2 type sturen. in nederland rijden veel H sturen. maar een m stuur is ook mogelijk.',
    verlichting: 'De CLine Electric is verkrijgbaar met geintergreed verlichting. deze werkt op de accu van de fiets.',
    kleur: 'De CLine Electric is verkrijgbaar in 6 kleuren. Black, Bumblebee Yellow, Dune Sand, Gloss Black Laquer, Ocean Blue, Racing Green.',
    },{
    id: 'TLine',
    default: 'De TLine is een sportieve voledig titanium vouwfiets met 1, 4 of 12 versnellingen. En weegt nog geen 8kg.',
    versnelling: 'De TLine is verkrijgbaar met 1, 4 of 12 versnellingen. 1 versnelling is de ligt gewicht optie. met 4 en 12 versnellingen voor de stad of langere afstanden.',
    zadel: 'De TLine is verkrijgbaar met een super light carbon zadel. dit zadel is licht en comfortabel.',
    zadelHoogte: 'De TLine is verkrijgbaar met 3 verschillende zadel hoogtes. extended is 5 cm hoger en telescopic is 10 cm hoger.',
    rack: 'De TLine komt met en zonder spatborden.',
    stuur: 'De TLine is verkrijgbaar met 2 type sturen. een m of s stuur.',
    verlichting: 'De TLine is verkrijgbaar met reflector of batterij verlichting.',
    kleur: 'De Tline is verkrijgbaar in blasted Ti.',
    },{
    id: 'PLine',
    default: 'De Pline is een deels titanium vouwfiets met optie tot elektrisch. De fiets is verkrijgbaar in 4 kleuren.',
    versnelling: 'De PLine is verkrijgbaar met 2 of 12 versnellingen. 2 versnellingen is ideal voor in de stad en 12 versnellingen voor langere afstanden.',
    zadel: 'De Pline komt met een super light carbon zadel. dit zadel is licht en comfortabel.',
    zadelHoogte: 'De Pline is verkrijgbaar met 3 verschillende zadel hoogtes. extended is 5 cm hoger en telescopic is 10 cm hoger.',
    rack: 'De PLine komt met spatborden. of met spatborden en bagagedrager.',
    stuur: 'De Pline is verkrijgbaar met 3 type sturen.',
    verlichting: 'De PLine is verkrijgbaar met reflector of batterij verlichting.',
    kleur: 'De PLine is verkrijgbaar in 6 kleuren. Midnight Black Matalic, Bolt Blue laquer, Bronze Sky, Lunar Ice',
    },{
    id: 'PlineElectric',
    default: 'De Pline Electric is een deels titanium electrische vouwfiets. De fiets is verkrijgbaar in 4 kleuren.',
    versnelling: 'De PLine Electric is verkrijgbaar met 2 of 12 versnellingen. 2 versnellingen is ideal voor in de stad en 12 versnellingen voor langere afstanden.',
    zadel: 'De Pline Electric komt met een super light carbon zadel. dit zadel is licht en comfortabel.',
    zadelHoogte: 'De PLine Electric is verkrijgbaar met 3 verschillende zadel hoogtes. extended is 5 cm hoger en telescopic is 10 cm hoger.',
    rack: 'De PLine Electric komt met spatborden. of met spatborden en bagagedrager.',
    stuur: 'De Pline Electric is verkrijgbaar met m of h stuur',
    verlichting: 'De PLine Electric is verkrijgbaar met geintergreed verlichting. deze werkt op de accu van de fiets.',
    kleur: 'De PLine Electric is verkrijgbaar in 4 kleuren. Midnight Black Matalic, Bolt Blue laquer, Bronze Sky, Lunar Ice',
    },{
    id: 'GLine',
    default: 'De GLine is de gravel vouwfiets voor avontuur en langere tochten. Met 20 inch wielen voor confort en snelheid.',
    versnelling: 'De GLine is verkrijgbaar met 8 versnellingen. Voor heuvels, bergen en vlak terein.',
    zadel: 'De Gline komt met een standaard zadel.',
    zadelHoogte: 'De Gline is verkrijgbaar met 3 verschillende zadel hoogtes. extended is 5 cm hoger en telescopic is 10 cm hoger.',
    rack: 'De GLine komt met spatborden. of met spatborden en bagagedrager.',
    stuur: 'De Gline komt met verhoogt S stuur voor meer controle en comfort',
    verlichting: 'De GLine is verkrijgbaar met reflector of batterij verlichting van Lezyne.',
    kleur: 'De Gline is verkrijgbaar in 3 kleuren. Adventure Orange, Forest Green, Traildust White',
    },{
    id: 'GLineElectric',
    default: 'De GLine Electric is de electriche gravel vouwfiets voor avontuur en langere tochten. Met 20 inch wielen voor confort en snelheid.',
    versnelling: 'De GLine Electric is verkrijgbaar met 8 versnellingen. Voor heuvels, bergen en vlak terein.',
    zadel: 'De Gline Electric komt met een standaard zadel.',
    zadelHoogte: 'De GLine Electric is verkrijgbaar met 3 verschillende zadel hoogtes. extended is 5 cm hoger en telescopic is 10 cm hoger.',
    rack: 'De GLine Electric komt met spatborden en bagagedrager.',
    stuur: 'De GLine Electric komt met verhoogt S stuur voor meer controle en comfort',
    verlichting: 'De GLine Electric is verkrijgbaar met geintergreed verlichting. deze werkt op de accu van de fiets.',
    kleur: 'De Gline Electric is verkrijgbaar in 3 kleuren. Adventure Orange, Forest Green, Traildust White',
    }];