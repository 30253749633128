export const bikes = [{
    id: 'ALine',
    startPrijs:  [1100],
    versnelling: [3],
    versnellingPrijs: [0],
    stuur: ['m'],
    stuurPrijs:[0],
    rack:['norackaline'],
    rackPrijs:[0],
    zadel: ['standaardsaddle'],
    zadelPrijs: [0],
    zadelHoogte: ['hoogtestandaard'],
    zadelHoogtePrijs: [0],
    verlichting: ['reflector'],
    verlichtingPrijs: [0],
    kleur: ['papyruswhite'], 
    kleurPrijs: [0],
},{
    id: 'CLine',
    startPrijs: [1599],
    versnelling: ['2', '6'],
    versnellingPrijs: [0, 150],
    stuur: ['s','m','h'],
    stuurPrijs:[0,0,0],
    rack:['mudgardcline', 'rackcline'],
    rackPrijs:[0,100],
    zadel: ['standaardsaddle', 'standaardwide', 'b17', 'c17'],
    zadelPrijs:[0,0,116,76],
    zadelHoogte: ['hoogtestandaard','hoogteextended','hoogtetelescopic'],
    zadelHoogtePrijs: [0,0,71],
    verlichting: ['reflector','battery','dynamo'],
    verlichtingPrijs: [0,100,180],
    kleur: ['blackmatt','bumblebeeyellow', 'dunesand', 'glossblacklacquer', 'oceanblue', 'racinggreen'], 
    kleurPrijs: [0,0,0,265,0,0],
},{
    id: 'CLineElectric',
    startPrijs: [3450],
    versnelling: ['4', '12'],
    versnellingPrijs: [0, 300],
    stuur: ['m','h'],
    stuurPrijs:[0,0],
    rack:['mudgardcline', 'rackcline'],
    rackPrijs:[0,63.82],
    zadel: ['standaardsaddle', 'standaardwide'],
    zadelPrijs:[0,0],
    zadelHoogte: ['hoogtestandaard','hoogteextended','hoogtetelescopic'],
    zadelHoogtePrijs: [0,0,42.54],
    verlichting: ['buildin'],
    verlichtingPrijs: [0],
    kleur: ['blackmatt','bumblebeeyellow', 'dunesand', 'glossblacklacquer', 'oceanblue', 'racinggreen'],
    kleurPrijs: [0,0,0,265,0,0],
},{
    id: 'TLine',
    startPrijs: [4950],
    versnelling: ['1','4', '12'],
    versnellingPrijs: [0,299, 574],
    stuur: ['m'],
    stuurPrijs:[0],
    rack:['mudgardtline'],
    rackPrijs:[0],
    zadel: ['carbonsaddle'],
    zadelPrijs:[0],
    zadelHoogte: ['hoogtestandaard','hoogteextended','hoogtetelescopic'],
    zadelHoogtePrijs: [0,0,71],
    verlichting: ['reflector','battery'],
    verlichtingPrijs: [0,100],
    kleur: ['blastedti'], 
    kleurPrijs: [0],
},{
    id: 'PLine',
    startPrijs: [2849],
    versnelling: ['4','12'],
    versnellingPrijs: [0,250],
    stuur: ['s','m','h'],
    stuurPrijs:[0,0,0],
    rack:['mudgardpline','rackpline'],
    rackPrijs:[0,110],
    zadel: ['carbonsaddle'],
    zadelPrijs:[0],
    zadelHoogte: ['hoogtestandaard','hoogteextended','hoogtetelescopic'],
    zadelHoogtePrijs: [0,0,71],
    verlichting: ['reflector','battery'],
    verlichtingPrijs: [0,100],
    kleur: ['midnightblackmetalic','boltbluelacquer','bronzesky','lunarice'], 
    kleurPrijs: [0,120,0,0],
},{
    id: 'PLineElectric',
    startPrijs: [4199],
    versnelling: ['4', '12'],
    versnellingPrijs: [0,200],
    stuur: ['m','h'],
    stuurPrijs:[0,0],
    rack:['mudgardpline','rackpline'],
    rackPrijs:[0,120],
    zadel: ['carbonsaddle'],
    zadelPrijs:[0],
    zadelHoogte: ['hoogtestandaard','hoogteextended','hoogtetelescopic'],
    zadelHoogtePrijs: [0,0,71],
    verlichting: ['buildin'],
    verlichtingPrijs: [0],
    kleur: ['midnightblackmetalic','boltbluelacquer','bronzesky','lunarice'], 
    kleurPrijs: [0,120,0,0],
},{
    id: 'GLine',
    startPrijs: [2969],
    versnelling: ['8'],
    versnellingPrijs: [0],
    stuur: ['s'],
    stuurPrijs:[0],
    rack:['norackgline','rackgline'],
    rackPrijs:[0,120],
    zadel: ['standaardsaddle'],
    zadelPrijs:[0],
    zadelHoogte: ['hoogtestandaard','hoogteextended','hoogtetelescopic'],
    zadelHoogtePrijs: [0,0,0],
    verlichting: ['reflector','lezyne'],
    verlichtingPrijs: [0,110],
    kleur: ['adventureorange','forestgreen','traildustwhite'], 
    kleurPrijs: [0,0,0],
},{
    id: 'GLineElectric',
    startPrijs: [3999],
    versnelling: ['8'],
    versnellingPrijs: [0],
    stuur: ['s'],
    stuurPrijs:[0],
    rack:['rackgline'],
    rackPrijs:[0],
    zadel: ['standaardsaddle'],
    zadelPrijs:[0],
    zadelHoogte: ['hoogtestandaard','hoogteextended','hoogtetelescopic'],
    zadelHoogtePrijs: [0,0,0],
    verlichting: ['buildin'],
    verlichtingPrijs: [0],
    kleur: ['adventureorange','forestgreen','traildustwhite'], 
    kleurPrijs: [0,0,0],
},
]